import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import * as S from '../styled';

import Layout from '../../components/Layout';
import Anchorlink from '../../components/Anchorlink';
import WrapperPage from '../../components/WrapperPage';
import WrapperSection from '../../components/WrapperSection';
import SEO from '../../components/SEO';

const TagRoute = ({ data, pageContext }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  });
  const posts = data.allMdx.edges;
  const postLinks = posts.map((post) => (
    <S.ListItem key={post.node.id}>
      <Anchorlink
        ariaLabel="tags"
        to={`/blog${post.node.frontmatter.slug}`}
      >
        <S.TagsTitleOnList>
          {post.node.frontmatter.title}
        </S.TagsTitleOnList>
      </Anchorlink>
    </S.ListItem>
  ));
  const { tag } = pageContext;
  const { totalCount } = data.allMdx;
  const endOfWord = () => {
    if (totalCount === 1) {
      return '';
    }
    if (totalCount < 5) {
      return 'y';
    }
    if (totalCount >= 5) {
      return 'ów';
    }
  };
  const tagHeader = `${totalCount} post${endOfWord()} otagowano frazą “${tag}”`;

  return (
    <Layout>
      <WrapperPage>
        <WrapperSection>
          <S.StyledContainer>
            <S.StyledTag>
              <S.TagsTitle>{tagHeader}</S.TagsTitle>
              <S.TagsList>{postLinks}</S.TagsList>
              <Anchorlink to="/tags/">{t('showTags')}</Anchorlink>
            </S.StyledTag>
          </S.StyledContainer>
        </WrapperSection>
      </WrapperPage>
    </Layout>
  );
};

export default TagRoute;

export const Head = ({ data }) => (
  <SEO
    title={`${data.site.siteMetadata.title} - Giedeé Informatyka`}
  />
);

export const tagPageQuery = graphql`
  query TagPage($tag: String, $locale: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { tags: { in: [$tag] } }
        fields: { locale: { eq: $locale } }
      }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            slug
            tags
            title
          }
        }
      }
    }
  }
`;
